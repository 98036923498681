<template>
  <v-autocomplete
    v-model="control.model[control.name]"
    v-bind="control"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    hide-no-data
    :no-filter="!control.static"
    class="jexy-autocomplete"
  />
</template>

<script>

// Mixin
import Control from './Mixin'

// Helpers
// import Vue from 'vue'

export default {
  name: 'Autocomplete',
  mixins: [ Control ],
  data() {
    return {
      isLoading: false,
      search: null,
      items: []
    }
  },
  watch: {
    search (query) {
      // Load default value if query is empty
      if (!query) this.loadItems()
      // Only if query is two characters or more
      if (!query || query.length < 2) return
      // Static list and items have already been loaded
      if (this.control.static && this.contronl.items.length > 0) return
      // Items have already been requested
      if (this.isLoading) return
      // Look up term in the current item list
      let item = this.items.find(item => item[this.control.itemText] == query)
      if (item) return
      // Not found anywhere, get a fresh item list.
      this.loadItems(query)
    }
  },
  mounted () {
    this.control.query().then((result) => {
      // Vue.set(this.control, 'items', result)
      this.items = result
    })
  },
  methods: {
    loadItems (query) {
      // Set loading state
      this.isLoading = true
      // Fetch input items
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.control.query(query).then((result) => {
          // Vue.set(this.control, 'items', result)
          this.items = result
        }).finally(() => {
          // Unset loading state
          this.isLoading = false
        })
      }, 300) /* 300ms throttle */
    }
  }
}
</script>
